import { App } from '../src/application';
import { BrownianMotion } from '../src/forces/brownianmotion';
import { Flicker } from '../src/forces/flicker';
import { SimplexNoise } from '../src/forces/simplexnoise';
import { Spring } from '../src/forces/spring';
import { EdgeForce } from '../src/forces/wraparound';
import { Model } from '../src/models/model';

import './landing.css';

const memorialeLink = document.querySelector('#memoriale') as HTMLElement;
const contribuisciLink = document.querySelector('#contribuisci') as HTMLElement;
const contribuisciEl = document.querySelector('.contrib') as HTMLElement;
const memorialeEl = document.querySelector('.memo') as HTMLElement;
const pixiCanvas = document.querySelector('#pixi') as HTMLCanvasElement;
const formContainer = document.querySelector('.form') as HTMLFormElement;
const form = document.querySelector('form') as HTMLFormElement;
const thanks = document.querySelector('.thanks') as HTMLDivElement;

form.addEventListener('submit', async function(event) {
    event.preventDefault();

    const formData = new FormData(form);
    if(formData.get('bot')) return;

    const data: Record<string, any> = {};
    formData.forEach((value, key) => data[key] = value);

    try {
        const res = await fetch('https://memoriale.vittimedeldovere.it/directus/items/segnalazioni', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
        if (res.status !== 204) throw new Error('Failed to submit form');
        form.reset();
        hiddenDiv.textContent = '';
        updateInput(mailInput, hiddenDiv);
        updateInput(nameInput, hiddenDiv);
        formContainer.classList.add('hidden');
        thanks.classList.add('show');

    } catch (error) {
        console.error('Error:', error);
        // Handle error (e.g., showing an error message)
    }

});

const hiddenDiv = document.querySelector('.hidden') as HTMLDivElement;

const nameInput = document.querySelector('#name') as HTMLInputElement;
nameInput.oninput = () => updateInput(nameInput, hiddenDiv);
nameInput.onfocus = () => updateInput(nameInput, hiddenDiv);

const mailInput = document.querySelector('#mail') as HTMLInputElement;
mailInput.oninput = () => updateInput(mailInput, hiddenDiv);
mailInput.onfocus = () => updateInput(mailInput, hiddenDiv);
function updateInput(inputEl: HTMLInputElement, hiddenDiv: HTMLDivElement, padding: number = 10) {
    hiddenDiv.textContent = inputEl.value;
    inputEl.style.width = `${hiddenDiv.offsetWidth + padding}px`;
}

memorialeLink.addEventListener('click', e => {
    e.preventDefault();
    window.scrollTo({ top: memorialeEl.offsetTop, behavior: 'smooth' });
});

contribuisciLink.addEventListener('click', e => {
    e.preventDefault();
    window.scrollTo({ top: contribuisciEl.offsetTop, behavior: 'smooth' });
});

const csvUrl = new URL('/landing.csv', import.meta.url).href
const model = new Model(csvUrl);
await model.load();

const app = new App(model, pixiCanvas, {
    backgroundColor: 0xFFFFFF,
    size: 60,
    sizeVariability: 10,
    mouseRadius: 0,
    spriteSets: [
        { 
            label:"quattro", 
            set: [
                new URL('/assets/sprites/1d.png', import.meta.url).href,
                new URL('/assets/sprites/2d.png', import.meta.url).href,
                new URL('/assets/sprites/3d.png', import.meta.url).href,
                new URL('/assets/sprites/4d.png', import.meta.url).href
            ]
        },
    ]
});
await app.init();

const wrapAround = new EdgeForce();
app.addForce('Wrap-around', wrapAround);

const brownian = new BrownianMotion();
app.addForce('Brownian Motion', brownian);

const simplexNoise = new SimplexNoise({
    enabled: true,
    showNoise: true,
    noiseFactor: 0,
    noiseSpeed: 500,
    noiseScale: 1500,
    noiseGridSize: 50,
    noiseGridScale: 20,
    blurEnabled: true,
    blurKernelSize: 15,
    blurQuality: 2,
    blurStrength: 15,
    blurResolution: 1,
    color1: 0x95CDFE,
    color2: 0x95CDFE, //FFC7FA,
});
app.addForce('Simplex Noise', simplexNoise);

const spring = new Spring({
    enabled: true,
});
app.addForce('Spring', spring);

const flicker = new Flicker({
    flickerEnabled: true,
    minFlickerOpacity: 0,
    maxFlickerOpacity: 1,
    flickerSpeed: 0.01,
});
app.addForce('Fliker', flicker);

(window as any).app = app;


const video = document.getElementById('intro-video') as HTMLVideoElement;
const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            video.play();
        } else {
            video.pause();
        }
    });
}, { threshold: 0.5 });
observer.observe(video);

const videoContainer = document.querySelector('.video') as HTMLDivElement;
const videoBackdrop = document.getElementById('video-backdrop') as HTMLDivElement;
const videoObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        videoBackdrop.style.opacity = entry.intersectionRatio.toString();
    });
}, { threshold: Array.from({ length: 101 }, (_, i) => i / 100) });

// if scrolled below the end of .video, set body snap-scrolling to true, else false
window.addEventListener('scroll', () => {
    const rect = videoContainer.getBoundingClientRect();
    const belowVideo = rect.bottom < 0;
    document.body.classList.toggle('below-video', belowVideo);
});


videoObserver.observe(videoContainer);
